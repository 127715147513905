<template>
  <Page id="privacypolicy" :class="mq">
    <div />
    <div id="privacypolicy-content" class="section-indent">
      <h1 class="privacypolicy-title">Our Privacy Policy</h1>

      <section class="privacypolicy">
        <h1>Your Rights</h1>
        <div>
          <p>
            We will collect and store the information that you provide us. You
            will be provided with opportunities to consent to this Privacy
            Policy and marketing when you provide your personal details to us.
          </p>
          <p>
            You can change your mind, remove or amend your consent at any time.
            Please see your list of rights below:
          </p>
          <li>
            You have the <b> right of access </b> to your personal records or
            other information that we hold about you. There is no administrative
            charge for this service.
          </li>
          <li>
            You have the
            <b> right to rectify any errors </b>
            in the data we hold about you. If any data or information we hold
            about you is inaccurate, or out of date, please contact us and we
            will correct this immediately.
          </li>
          <li>
            You have the
            <b> right to have the data we hold about you erased </b>.
          </li>
          <li>
            If you wish us to continue to store your information but
            <b> wish us to keep your data separate </b> and not process it in
            any way, please let us know.
          </li>
          <li>
            You have the
            <b> right to object to us processing your personal data, </b>
            for direct marketing purposes for example. You may also specify that
            you object to direct marketing by particular channels such as by
            email or telephone. If this is the case, please specify the channels
            you are objecting to in your communications with us.
          </li>
          <li>
            You have the <b> right to data portability </b>. If you wish to
            obtain your data for your own purposes across different services, we
            will provide this information to you in a CSV file. There is no
            administrative charge for this service.
          </li>
          <li>
            You have the
            <b> right to object to any direct marketing </b>.
          </li>
        </div>
      </section>

      <section class="privacypolicy">
        <h1>Data Collection Storage Time</h1>
        <div>
          <p>
            We will store the data that you provide us for as long as necessary
            for the purpose of processing. The data may be deleted in the
            following circumstances:
          </p>
          <li>You have withdrawn your consent to data processing.</li>
          <li>
            The original purpose for processing the data is no longer relevant
            or cannot be performed anymore.
          </li>
          <li>The data is no longer up to date or accurate.</li>
        </div>
      </section>

      <section class="privacypolicy">
        <h1>Changes to This Privacy Policy</h1>
        <p>
          We may change this Policy from time to time. You should check this
          policy frequently to ensure you are aware of the most recent version
          that will apply each time you use the Website.
        </p>
      </section>

      <section class="privacypolicy">
        <h1>Our Analytics</h1>
        <p>
          We value your privacy and anonymity. For this reason, we have opted to
          use a traffic analytics platform called
          <a href="https://Microanalytics.io" target="_blank">Microanalytics</a
          >. As of the date of this privacy policy, this platform does not
          collect any identifiable information on our visitors - this includes
          IP addresses, fingerprints or cookies.
        </p>
      </section>

      <section class="privacypolicy">
        <h1>Want to contact us?</h1>
        <p>
          We welcome your feedback and questions about our privacy policy and
          data handling procedures. If you wish to contact us, please send us an
          email at
          <a href="MAILTO:compliance@viscreo.co.uk">compliance@viscreo.co.uk</a
          >. Please see our contact page for general enquiries.
        </p>
      </section>

      <section class="privacypolicy">
        <h1>Data Protection Supervisory Authority</h1>
        <p>
          The Data Protection Supervisory Authority in the UK is the Information
          Commissioners' Office. Should you have any complaints about the way we
          handle your data, you may direct them to the ICO. More information on
          the ICO can be found on their
          <a href="https://ico.org.uk">website here</a>
        </p>
      </section>

      <section id="privacypolicy-download">
        <div id="privacypolicy-download-top">
          <p>Last updated: 25/07/2022</p>
          <h2>
            Click below to download a PDF copy of our latest privacy policy!
          </h2>
        </div>
        <Button value="Download" @click="openPDF">
          <template v-slot:icon><div /></template>
          <template v-slot:icon2><div /></template>
        </Button>
      </section>
    </div>
  </Page>
</template>

<script>
import Button from "@/components/global/Button.vue";
import Page from "@/components/core/Page.vue";

export default {
  inject: ["mq"],
  components: { Button, Page },
  methods: {
    openPDF() {
      window.open("/privacy_policy_25_07_2022.pdf", "_blank");
    },
  },
};
</script>

<style scoped>
#privacypolicy {
  gap: 80px;
}

#privacypolicy-content {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  gap: 80px;
}

.privacypolicy {
  flex-direction: column;
  margin-bottom: 80px;
  text-align: justify;
  display: flex;
  width: 100%;
  gap: 40px;
}

.brMobile .privacypolicy {
  text-align: start;
}

.privacypolicy > div {
  flex-direction: column;
  display: flex;
  gap: 20px;
}

.privacypolicy h1 {
  text-decoration: underline;
  color: var(--accent);
}

.privacypolicy h3 {
  text-decoration: underline;
  margin-top: 20px;
}

.privacypolicy a {
  font-weight: bold;
}

.privacypolicy .highlight {
  color: var(--primary);
  font-weight: bold;
}

#privacypolicy-download,
#privacypolicy-download-top {
  flex-direction: column;
  align-items: center;
  display: flex;
  gap: 20px;
}

#privacypolicy-download-top {
  text-align: center;
  gap: 5px;
}

#privacypolicy-download-top > p {
  font-size: var(--font-small);
  color: var(--grey);
}

#privacypolicy-download-top > h2 {
  font-weight: normal;
}
</style>
